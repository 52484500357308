import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
interface Props {
  onClick: () => void
  active: boolean
  ariaLabel: string
  label?: string
  isLabelHtml?: boolean
  color?: string
  borderColor?: string
  textColor?: string
  disabled?: boolean
  textAlign?: string
}

const Checkbox = ({
  onClick,
  active,
  ariaLabel,
  label,
  isLabelHtml,
  color,
  borderColor,
  textColor,
  disabled,
  textAlign = 'center',
}: Props) => {
  const [Theme] = useTheme()

  return (
    <Button onClick={!disabled ? onClick : undefined} disabled={disabled} aria-label={ariaLabel}>
      <CheckboxContainer
        active={active}
        color={color || Theme.colors.primaryText}
        borderColor={borderColor || Theme.colors.primaryText}
        disabled={disabled}>
        {active && <Icon name="check" size={15} color={Theme.colors.white} cursor="pointer" />}
      </CheckboxContainer>
      {!!label &&
        (isLabelHtml ? (
          <Label
            dangerouslySetInnerHTML={{ __html: label }}
            color={textColor || Theme.colors.primaryText}
            textAlign={textAlign}
          />
        ) : (
          <Label color={textColor || Theme.colors.primaryText} textAlign={textAlign}>
            {label}
          </Label>
        ))}
    </Button>
  )
}

export default Checkbox

const Button = styled('button')<{ disabled?: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
  ${(props) => (props.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}
  padding: unset;
  align-items: center;
`

const CheckboxContainer = styled('div')<{ color: string; borderColor: string; active: boolean; disabled?: boolean }>`
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.disable : props.active ? props.color : 'transparent'};
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.disable : props.active ? props.color : props.borderColor)};
  transition: background-color 0.2s ease-in-out;
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`

const Label = styled('p')<{ color: string; disabled?: boolean; textAlign: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  margin: 0px 0px 0px 10px;
  word-break: break-word;
`
