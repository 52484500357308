import * as React from 'react'
import Provider from 'theme/Provider'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import CloseModalButton from 'components/button/CloseModalButton'
import DateTimePicker from 'react-date-picker'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import useBreakpoints from 'utils/useBreakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import './react-date-picker.css'

interface Props {
  title: string
  date: Date
  min?: Date
  max?: Date
  onDateSelected: (date: Date) => void
  buttonColor?: string
}

const DatePickerModal = ({ title, date, min, max, onDateSelected, buttonColor }: Props) => {
  const i18n = useI18n()
  const [, bp] = useBreakpoints()
  const dateFormat = !i18n.langCode || i18n.langCode === 'fr-FR' ? 'dd/MM/y' : undefined

  const [current, setCurrent] = React.useState(date)

  useEscKeyPressed(DatePickerPortal.close)

  const save = () => {
    onDateSelected(current)
    DatePickerPortal.close()
  }

  // Props oubliée par la lib mais le champ existe
  const unregisteredProps = { disableCalendar: bp === 'phone' }

  return (
    <MainContainer>
      <Back />

      <FocusLock autoFocus={true} returnFocus>
        <Container
          id="DatePickerModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalDatePickerHeading"
          tabIndex={-1}>
          <HeaderContainer>
            <Title id="modalDatePickerHeading">{title}</Title>
            <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={DatePickerPortal.close} />
          </HeaderContainer>
          <DateTimePicker
            value={current}
            onChange={setCurrent}
            locale={i18n.langCode || 'fr-FR'}
            format={dateFormat}
            minDate={min}
            maxDate={max}
            clearAriaLabel={i18n.t('accessibility.ariaLabels.booking.clearSelection')}
            calendarAriaLabel={i18n.t('accessibility.ariaLabels.booking.calendarIcon')}
            {...unregisteredProps}
          />

          <ButtonContainer>
            <Button color={buttonColor} label={i18n.t('common.validate')} onClick={save} disabled={!current} />
          </ButtonContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  width: 520px;
  margin: 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  border-radius: 15px;
  gap: 30px;

  input,
  span {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100vw - 80px);
    border-radius: 10px;
    gap: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  align-items: center;
  z-index: -1;
`

let modalRoot: HTMLElement | null

const DatePickerPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <DatePickerModal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default DatePickerPortal
