import * as React from 'react'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import { HEADER_HEIGHT } from 'components/main/Main'

import toast, { Toaster } from 'react-hot-toast'

interface ToastProps {
  label: string
  icon: IconName
  id?: string
}

export const showToast = ({ label, icon, id }: ToastProps) => {
  toast(label, { icon: <Icon name={icon} size={32} />, id })
}

const Toast = () => {
  const [theme] = useTheme()

  return (
    <Toaster
      toastOptions={{
        duration: 5000,
        style: {
          background: theme.colors.background,
          alignSelf: 'center',
          flexDirection: 'row',
          marginTop: HEADER_HEIGHT,
          minWidth: 360,
          ...theme.fonts.content,
        },
      }}
    />
  )
}

export default Toast
