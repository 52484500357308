import { createEvent, createStore } from 'effector'

export const actions = {
  setRestaurants: createEvent<RestaurantV6[]>('setRestaurants'),
  resetRestaurants: createEvent('resetRestaurants'),
  setAttendances: createEvent<RestaurantsAttendances>('setAttendances'),
  resetAttendances: createEvent('resetAttendances'),
  reset: createEvent('reset'),
}

const initialState: RestaurantState = {
  restaurants: [],
  attendances: {},
}

export const store = createStore<RestaurantState>(initialState, { name: 'restaurant' })
  .on(actions.setRestaurants, (s, restaurants) => ({ ...s, restaurants }))
  .on(actions.resetRestaurants, (s) => ({ ...s, news: initialState.restaurants }))
  .on(actions.setAttendances, (s, attendances) => ({ ...s, attendances }))
  .on(actions.resetAttendances, (s) => ({ ...s, news: initialState.attendances }))
  .on(actions.reset, () => ({ ...initialState }))
