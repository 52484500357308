import * as React from 'react'
import styled from 'theme/styled-components'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import Loader from 'components/status/Loader'
import Icon from 'components/icons/Icon'
import Animator from 'components/animation/Animator'
import Tree from 'components/button/Tree'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'

import analytics, { ScreenContext } from 'utils/analytics'
import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'

import values from 'firebaseanalytics/firebaseValues.json'

import api from './api'
import theme from 'theme/Theme'

interface Props {
  context?: ScreenContext
}

type ParkingType = 'standard' | 'electric' | 'pmr'

const ParkingScreen = ({ context }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SitesStore.store, (s) => s.site)

  const [loading, setLoading] = React.useState(true)
  const [parkingList, setParkingList] = React.useState<ParkingHistoryInfos>()

  const fetch = () => {
    if (!!site) {
      api
        .getParking(i18n.lang, site.id)
        .then((data) => setParkingList(data))
        .catch(Logger.error)
        .finally(() => setLoading(false))
    }
  }

  const parkingData: ParkingHistoryInfo[] = React.useMemo(() => {
    if (!parkingList) {
      return []
    }
    return parkingList.parkingInfos
  }, [parkingList])

  const isAlmostFull = (place: ParkingLevelInfo[]) => {
    const free = place.reduce((acc, curr) => acc + (curr.free || 0), 0)
    if (free <= 1) return true

    const total = place.reduce((acc, curr) => acc + (curr.capacity || 0), 0)
    return free / total < 0.1
  }

  const getFreePlace = (place: ParkingLevelInfo[]) => {
    return place.reduce((acc, curr) => acc + (curr.free || 0), 0)
  }

  const getFullCapacity = (place: ParkingLevelInfo[]) => {
    return place.reduce((acc, curr) => acc + (curr.capacity || 0), 0)
  }

  React.useEffect(() => {
    analytics.screen({
      screen_context: context,
      screen_feature: values.screens.move,
      screen_name: values.screens.parking,
    })
  }, [])

  React.useEffect(() => {
    fetch()
  }, [site])

  const renderParkingLevel = ({ level, free }: ParkingLevelInfo) => {
    return <LevelPlaces>{i18n.t('screens.parking.level', { level, free })}</LevelPlaces>
  }

  const renderParkingPlace = ({
    type,
    place,
    index,
  }: {
    type: ParkingType
    place: ParkingLevelInfo[]
    index: number
  }) => {
    const icon = {
      pmr: { color: theme.colors.blablacar, name: 'pmr_bold' as IconName },
      standard: { color: theme.colors.valid, name: 'car' as IconName },
      electric: { color: theme.colors.electric, name: 'electric' as IconName },
    }

    return (
      <Item key={index}>
        <ParkingTypeContainer>
          <IconContainer color={icon[type].color || theme.colors.primaryText}>
            <Icon name={icon[type].name || 'car'} size={25} color={theme.colors.white} />
          </IconContainer>
          <PlaceType>{i18n.t(`screens.parking.${type}`)}</PlaceType>
        </ParkingTypeContainer>
        <PlaceContainer>
          <TotalPlaceContainer>
            <TotalFree almostFull={isAlmostFull(place)}>{getFreePlace(place)}</TotalFree>
            <TotalPlace>{i18n.t('screens.parking.totalPlace', { total: getFullCapacity(place) })}</TotalPlace>
          </TotalPlaceContainer>
          <LevelsContainer>{place.map(renderParkingLevel)}</LevelsContainer>
        </PlaceContainer>
      </Item>
    )
  }

  const renderParkingSite = (item: ParkingHistoryInfo, index: number) => {
    return (
      <div key={index}>
        <Title>{i18n.t('screens.parking.site', { site: item.site })}</Title>
        {item.places &&
          Object.entries(item.places).map((place, index) =>
            renderParkingPlace({ type: place[0] as ParkingType, place: place[1], index })
          )}
      </div>
    )
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.parking.title')} />
        <Tree
          previousPages={[
            {
              url: 'move',
              title: `screens.move.title${site?.alternativeFunctionalityDesign ? '_alt' : ''}`,
            },
          ]}
          currentPageTitle={i18n.t(`screens.parking.title${site?.alternativeFunctionalityDesign ? '_alt' : ''}`)}
        />
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : parkingData.length > 0 ? (
          <>
            <Animator isList gap={30}>
              {parkingData.map(renderParkingSite)}
            </Animator>
            <ChargeText href="https://my.ze-watt.com./login/?/=/" target="_blank">
              {i18n.t('screens.parking.howToCharge')}
            </ChargeText>
          </>
        ) : (
          <NoInfo>{i18n.t('screens.parking.noInfo')}</NoInfo>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default ParkingScreen

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 70px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 20px 0 40px 0;
`

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 14px 20px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border-radius: 8px;
  margin: 20px 0;
  height: 90px;
`

const NoInfo = styled('p')`
  margin: 40px 20px;
  text-align: center;
  ${(props) => props.theme.fonts.subtitle};
`

const ParkingTypeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled('div')<{ color: string }>`
  border-radius: 6px;
  padding: 12px;
  overflow: hidden;
  background-color: ${(props) => props.color};
`

const PlaceType = styled('p')`
  margin-left: 10px;
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryText};
`

const PlaceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
`

const TotalPlaceContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
`

const TotalFree = styled('p')<{ almostFull: boolean }>`
  ${(props) => props.theme.fonts.h2Bold};
  font-size: 42px;
  color: ${(props) => (props.almostFull ? props.theme.colors.warning : props.theme.colors.valid)};
  line-height: 42px;
  margin: 0 8px 4px 0;
`

const TotalPlace = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 32px;
`

const LevelsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 4px 0 10px 0;
`

const LevelPlaces = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.middleGrey};
`

const ChargeText = styled('a')`
  ${(props) => props.theme.fonts.subtitle};
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.primaryText};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 30px 0;
`
