import ws from 'utils/Webservice'

const api = {
  getCateringPlaces: (siteId: number) => ws<CateringPlaces>(`/catering/${siteId}/cateringPlaces`, 'GET'),

  getCateringCostCenters: (siteId: number) => ws<CostCenters>(`/catering/${siteId}/costCenters`, 'GET'),

  getCateringFood: (siteId: number) => ws<Foods>(`/catering/${siteId}/food`, 'GET'),

  getCateringInstantTypes: (siteId: number) => ws<InstantTypes>(`/catering/${siteId}/instantTypes`, 'GET'),

  getSiteParameter: (siteId: number) => ws<SiteParameter>(`/catering/${siteId}/siteParameter`, 'GET'),

  getCateringDivisions: (siteId: number) => ws<Division[]>(`/catering/${siteId}/divisions`, 'GET'),

  getCateringInstants: () => ws<InstantsList>(`/catering/instants`, 'GET'),

  getCateringInstantById: (instantId: string) => ws<InstantDetailed>(`/catering/instants/${instantId}`, 'GET'),

  createCateringInstant: (instantToCreate: InstantToCreate) =>
    ws<InstantDetailed>(`/catering/instants`, 'POST', instantToCreate),

  updateCateringInstant: (instantId: string, instantToCreate: InstantToCreate) =>
    ws<InstantDetailed>(`/catering/instants/${instantId}`, 'PUT', instantToCreate),

  deleteCateringInstant: (instantId: string) => ws<InstantDetailed>(`/catering/instants/${instantId}`, 'DELETE'),
}

export default api
