import * as React from 'react'
import styled from 'theme/styled-components'

import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import WarningLabel from 'components/label/WarningLabel'

import RegisterDrawerContent from './RegisterDrawerContent'
import AnimationDetailContent from './AnimationDetailContent'

import useNavigation from 'core/src/layout/useNavigation'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import { isAfter } from 'date-fns'
import { fetchAnimations, fetchReservations, filterValidSlots, findSlot, groupeSlotsByDay } from './utils'
import { capitalize } from 'utils/stringUtils'
import Logger from 'utils/Logger'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  detail: AnimationDetail
  alreadyBooked: Booking[]
  bookingId?: string
  mode?: 'BOOK' | 'MODIFY'
  isDelegated: boolean
  emailData?: AnimationEmailUser
  isEligible: boolean
  couldBookNewSlot: boolean
}

const SlotsList = ({
  detail,
  alreadyBooked,
  bookingId,
  mode = 'BOOK',
  isDelegated,
  emailData,
  isEligible,
  couldBookNewSlot,
}: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const site = useReducer(SiteStore.store, (s) => s.site)

  const [isBooking, setIsBooking] = React.useState(false)
  const [selectedDay, setSelectedDay] = React.useState<string>()

  // Créneaux dont la date d'inscription est valide
  const validSlots = React.useMemo(() => filterValidSlots(detail?.slots), [detail])

  // Créneaux regroupés par date
  const groupedSlots = React.useMemo(() => groupeSlotsByDay(validSlots), [validSlots])

  // Tous les créneaux du jour sélectionné (si jours multiples)
  const slotsOfSelectedDay = React.useMemo(() => {
    if (validSlots) {
      const filteredSlots = !!selectedDay
        ? validSlots.filter((slot) => slot.startDate.includes(selectedDay))
        : validSlots
      return filteredSlots.sort((a, b) => a.startDate.localeCompare(b.startDate))
    }
    return []
  }, [validSlots, selectedDay])

  // Jours de réservation disponibles
  const slotsDays = React.useMemo(() => (!!groupedSlots ? Object.keys(groupedSlots) : []), [groupedSlots])
  const multipleDays = React.useMemo(() => slotsDays.length > 1 && couldBookNewSlot, [slotsDays, couldBookNewSlot])

  // Liste des jours des autres réservations
  const alreadyBookedDates = React.useMemo(
    () =>
      alreadyBooked
        // Si on est en train de modifier une réservation, on ne garde que les dates des autres réservations
        .filter((booking) => mode !== 'MODIFY' || booking.id !== bookingId)
        .map((booking) => booking.startDate.split('T')[0])
        .filter(Boolean),
    [alreadyBooked]
  )

  const reservation = React.useMemo(() => alreadyBooked?.find((r) => r.id === bookingId), [alreadyBooked, bookingId])

  const isFull = React.useMemo(() => detail?.isFull || validSlots.length === 0, [detail, validSlots])

  const canBook = React.useMemo(() => !!detail && !isFull && isEligible, [!!detail, isFull, isEligible])

  const canBookMultipleSlotsMultipleDays = React.useMemo(
    () => !!detail.multipleSlotByUser && !!selectedDay && !alreadyBookedDates.includes(selectedDay),
    [detail.multipleSlotByUser, alreadyBookedDates, selectedDay]
  )

  const canBookMultipleSlotsSameDay = React.useMemo(
    () => !!detail.multipleSlotByDay && !!selectedDay && alreadyBookedDates.includes(selectedDay),
    [detail.multipleSlotByDay, alreadyBookedDates, selectedDay]
  )

  const canBookMultipleSlots = React.useMemo(
    () =>
      // L'animation accepte plusieurs réservations par utilisateur sur des jours différents
      canBookMultipleSlotsMultipleDays ||
      // L'utilisateur peut réserver plusieurs créneaux le même jour
      canBookMultipleSlotsSameDay ||
      (mode === 'MODIFY' && alreadyBookedDates.length === 0),
    [canBookMultipleSlotsMultipleDays, canBookMultipleSlotsSameDay, mode, alreadyBookedDates]
  )

  const canModify = mode === 'MODIFY' && !!reservation

  React.useEffect(() => {
    if (!!slotsDays && slotsDays.length > 0) {
      setSelectedDay(slotsDays[0])
    }
  }, [slotsDays])

  const onSlotSelect = (slot: Slot) => {
    if (canBook) {
      Alert.open({
        title: i18n.t('screens.register.detail.confirmBooking'),
        children: <AnimationDetailContent detail={detail} slot={slot} email={emailData?.email} small />,
        buttons: [
          {
            label: i18n.t('common.cancel'),
            onClick: Alert.close,
            style: 'secondary',
          },
          {
            label: i18n.t('common.confirm'),
            onClick: () => {
              if (!!detail.imageRights && !isDelegated) {
                imageRightsModal(slot)
              } else {
                const action = () =>
                  canModify
                    ? api.modify(detail.id, slot.id, reservation.id)
                    : api.book(
                        detail.id,
                        slot.id,
                        !!detail.imageRights ? 'REGISTERED_BY_ANOTHER' : undefined,
                        emailData?.data?.realEmailUser || emailData?.email
                      )
                bookApi(action)
              }
            },
          },
        ],
      })
    } else {
      Alert.open({
        title: i18n.t('screens.register.detail.bookResult.bookFailed'),
        description: i18n.t('screens.register.detail.bookResult.modifyError'),
      })
    }
  }

  // Droits à l'image, non requis si on incrit un autre collaborateur
  const imageRightsModal = (slot: Slot) => {
    if (!!detail && !!detail.imageRights) {
      const { optionNo, optionYes, text, title } = detail.imageRights
      Alert.open({
        title,
        description: text,
        isDescriptionHtml: true,
        checkBoxList: [
          { id: 'CONSENT', label: optionYes, textAlign: 'left', isLabelHtml: true },
          { id: 'NO_CONSENT', label: optionNo, textAlign: 'left', isLabelHtml: true },
        ],
        buttons: [
          {
            label: i18n.t('common.cancel'),
            onClick: Alert.close,
            style: 'secondary',
          },
          {
            label: i18n.t('common.continue'),
            onClick: (checkBoxStatus) => {
              if (!!checkBoxStatus && (checkBoxStatus === 'CONSENT' || checkBoxStatus === 'NO_CONSENT')) {
                const action = () =>
                  canModify
                    ? api.modify(detail.id, slot.id, reservation.id, checkBoxStatus)
                    : api.book(detail.id, slot.id, checkBoxStatus)
                bookApi(action)
              }
            },
            canBeDisabled: true,
          },
        ],
      })
    }
  }

  const bookApi = (action: () => Promise<Booking>) => {
    if (!isBooking) {
      Alert.close()
      setIsBooking(true)
      analytics.event({
        event_feature: values.eventName.register,
        event_action: values.actions[canModify ? 'editBooking' : 'validateBooking'],
        event_object_id: canModify ? bookingId : detail.id,
      })
      action()
        .then((newBooking) => {
          navigation.push('/register?sectionIndex=1')
          setTimeout(
            () =>
              Modal.open({
                Content: () => (
                  <RegisterDrawerContent
                    type={`${mode}_SUCCEED`}
                    email={emailData?.email}
                    cardType={findSlot(newBooking.slotId ?? '', detail?.slots ?? [])?.invitationCardType || undefined}
                    booking={newBooking}
                  />
                ),
              }),
            500
          )
        })
        .catch((err) => {
          Logger.error(err)
          Alert.open({
            title: i18n.t('screens.register.detail.bookResult.bookFailed'),
            description:
              !!emailData?.email && err.code === 400
                ? i18n.t('screens.register.detail.bookResult.alreadyBookedCollaborator', { email: emailData.email })
                : i18n.t(`screens.register.detail.bookResult.${mode.toLowerCase()}Error`),
          })
          setIsBooking(false)
        })
        .finally(() => {
          fetchAnimations(site?.id)
          fetchReservations(true)
          fetchReservations(false)
        })
    }
  }

  const renderDay = (item: string) => {
    const date = new Date(item)

    return (
      <li key={item}>
        <DayContainer selected={item === selectedDay} onClick={() => setSelectedDay(item)}>
          <DayNumber>{i18n.t('screens.register.detail.dayNumber', { date })}</DayNumber>
          <DayName>{capitalize(i18n.t('screens.register.detail.monthString', { date }).slice(0, 3) + '.')}</DayName>
          <DayName>{capitalize(i18n.t('screens.register.detail.dayString', { date }).slice(0, 3) + '.')}</DayName>
        </DayContainer>
      </li>
    )
  }

  const renderSlot = (item: Slot) => {
    const numberUser = item.numberUser || 0
    const limitUser = item.limitUser || 0
    const isAvailable = numberUser < limitUser
    const isAfterMaxDate = isAfter(new Date(), new Date(item.endDateInscription))
    const isAlreadyBooked = alreadyBooked.some((booked) => booked.slotId === item.id)
    const isBookable = isAvailable && !isAlreadyBooked && !isAfterMaxDate

    return (
      <SlotContainer
        key={item.id}
        isAlreadyBooked={isAlreadyBooked}
        isBookable={isBookable && canBook}
        onClick={() => {
          if (isBookable && canBook) {
            onSlotSelect(item)
          }
        }}>
        <SlotInfo>
          <SlotTime>
            {i18n.t('screens.home.featureDetail.timeSlot', {
              start: new Date(item.startDate),
              end: new Date(item.endDate),
            })}
          </SlotTime>

          <Line>
            <SlotOccupancy colored isAvailable={isAvailable && !isAfterMaxDate}>
              {!isAvailable
                ? i18n.t('screens.register.detail.full')
                : isAfterMaxDate
                ? i18n.t('screens.register.detail.dateMaxReached')
                : i18n.t('screens.register.detail.available', { count: limitUser - numberUser })}
            </SlotOccupancy>
            <SlotOccupancy>{` • ${item.place || detail.place}`}</SlotOccupancy>
          </Line>

          {isBookable && (
            <SlotOccupancy>
              {i18n.t('screens.register.detail.deadline', { date: new Date(item.endDateInscription) })}
            </SlotOccupancy>
          )}
        </SlotInfo>

        <ButtonContainer>
          <Button label={i18n.t('screens.register.detail.book')} onClick={() => null} width={90} />
        </ButtonContainer>
      </SlotContainer>
    )
  }

  return (
    <>
      {canBook && (
        <MainContainer>
          {multipleDays && <DaysList>{slotsDays.map(renderDay)}</DaysList>}
          {alreadyBooked.length === 0 || canBookMultipleSlots ? (
            <List marginTop={!!multipleDays}>{slotsOfSelectedDay.map(renderSlot)}</List>
          ) : (
            <WarningContainer multipleDays={multipleDays}>
              <WarningLabel
                description={
                  !!detail.multipleSlotByUser && !canBookMultipleSlotsMultipleDays
                    ? i18n.t('screens.register.detail.restricted.alreadyBookUserOnlyOneADay')
                    : !!detail.multipleSlotByDay && !canBookMultipleSlotsSameDay
                    ? i18n.t('screens.register.detail.restricted.alreadyBookUserRestrictDay')
                    : i18n.t('screens.register.detail.restricted.alreadyBookUserSimple')
                }
              />
            </WarningContainer>
          )}
        </MainContainer>
      )}
    </>
  )
}

export default SlotsList

const MainContainer = styled('div')`
  position: relative;
`

// DAYS LIST

const Text = styled('p')`
  margin: 0;
  padding: 0;
`

const DaysList = styled('ul')`
  position: absolute;
  list-style: none;
  display: flex;
  width: 100%;
  gap: 12px;
  margin: 0;
  padding: 0 0 16px;

  // Scrollbar non visible mais scroll autorisé :
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; // Internet Explorer, Edge
  scrollbar-width: 0px; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`

const DayContainer = styled('button')<{ selected: boolean }>`
  flex-direction: column;
  width: 62px;
  height: 62px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.primaryDark : props.theme.colors.lightGrey)};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.colors.contentBackground : props.theme.colors.white)};
  transition: all 0.25s ease-in-out;
  :hover {
    box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  }
`

const DayNumber = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
`

const DayName = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

// SLOTS LIST

const List = styled('ul')<{ marginTop: boolean }>`
  list-style: none;
  margin: ${(props) => (props.marginTop ? 70 : 0)}px 0 0;
  padding: 0;
`

const ButtonContainer = styled('div')`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`

const SlotContainer = styled('div')<{ isAlreadyBooked?: boolean; isBookable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.isAlreadyBooked ? props.theme.colors.contentBackground : props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  padding: 20px 16px;
  box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  margin-top: 16px;
  transition: all 0.25s ease-in-out;
  ${(props) =>
    props.isBookable &&
    `cursor: pointer;
    :hover {
      box-shadow: 0px 5px 6px 4px rgba(161, 161, 161, 0.25);
    }
    :hover ${ButtonContainer} {
      opacity: 1;
    }`};
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

const SlotOccupancy = styled(Text)<{ colored?: boolean; isAvailable?: boolean }>`
  ${(props) => (props.colored ? props.theme.fonts.subtitleBold : props.theme.fonts.subtitle)};
  color: ${(props) => props.colored && (props.isAvailable ? props.theme.colors.available : props.theme.colors.full)};
  text-decoration: none;
  margin-top: 4px;
`

const Line = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

// Restricted animation

const WarningContainer = styled.div<{ multipleDays: boolean }>`
  ${(props) => props.multipleDays && 'margin-top: 80px'};
  max-width: 700px;
`
