import * as React from 'react'
import styled from 'theme/styled-components'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Tree from 'components/button/Tree'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import Toast, { showToast } from 'components/alert/Toast'

import CateringForm, { FormValues, MAX_HOUR } from './CateringForm'

import useI18n from 'i18n/useI18n'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { areSameValues, formatDate, formatInstantRequest } from './utils'
import { isBefore, differenceInBusinessDays } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

type FormMode = 'DETAIL' | 'UPDATE'

const CateringDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const sites = useReducer(SiteStore.store, (s) => s.sites)

  const [lastSiteId, setLastSiteId] = React.useState<number>()
  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [detail, setDetail] = React.useState<InstantDetailed>()
  const [formMode, setFormMode] = React.useState<FormMode>('DETAIL')
  const [modifiedValues, setModifiedValues] = React.useState<Partial<FormValues>>()
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>()

  const now = new Date()

  const instantSite = React.useMemo(() => sites.find((s) => s.id.toString() === detail?.siteId), [
    sites,
    detail?.siteId,
  ])

  const instantStatus = React.useMemo(() => {
    if (!!detail) {
      if (detail.status === 'CANCELED') {
        return 'canceled'
      }
      const instantDate = formatDate(detail.date, detail.startingTime)
      if (isBefore(instantDate, now)) {
        return 'past'
      }
      const diffDays = differenceInBusinessDays(instantDate, now)
      if (
        diffDays < detail.instantType.timeAheadDay ||
        (diffDays === detail.instantType.timeAheadDay && now.getHours() >= MAX_HOUR)
      ) {
        return 'near'
      }
      return 'editable'
    }
  }, [detail, now])

  // On vérifie la validité des valeurs du formulaire, et si un champ du formulaire a été modifié par rapport à la réservation initiale
  const isFormInvalid = React.useMemo(
    () =>
      !detail ||
      !modifiedValues ||
      (!!formErrors && Object.keys(formErrors).length > 0) ||
      areSameValues(detail, modifiedValues),
    [detail, modifiedValues, formErrors]
  )

  React.useEffect(() => {
    setTimeout(() => setLastSiteId(site?.id), 1000)
  }, [site?.id])

  React.useEffect(() => {
    if (!!site && !!instantSite && site.id !== instantSite.id) {
      const isCateringActivated = site.functionalities.some((f) => f.type === 'CATERING' && f.activated)

      if (!isCateringActivated) {
        // Cas où le Catering n'est pas activé sur le site actuel de l'utilisateur
        if (!lastSiteId) {
          // Arrivée sur la page Catering via un lien : on force le changement de site et on affiche un Toast
          SiteStore.actions.setSite(instantSite)
          showToast({
            label: i18n.t('screens.meeting.catering.detail.newSite', { site: instantSite.name }),
            icon: 'pin',
            id: 'cateringNewSiteToast',
          })
        } else {
          // Changement de site depuis la page Catering : redirige vers la page Home
          navigation.push('/')
        }
      }
    }
  }, [site?.id, instantSite?.id])

  React.useEffect(() => {
    if (!!id) {
      setStatus('loading')

      analytics.screen({
        screen_feature: values.screens.catering,
        screen_name: values.screens.cateringDetail,
        screen_object_id: id,
      })

      api
        .getCateringInstantById(id)
        .then((detail) => {
          setDetail(detail)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [id])

  const modifyInstant = () => {
    analytics.event({
      event_feature: values.eventName.catering,
      event_action: values.actions.editBooking,
      event_object_id: id,
    })

    Alert.close()
    if (!!instantSite && !!detail && modifiedValues) {
      api
        .updateCateringInstant(id, formatInstantRequest(instantSite.id, detail, { ...modifiedValues }))
        .then((updatedDetail) => {
          setDetail(updatedDetail)
          Alert.open({
            title: i18n.t('common.confirmation'),
            description: i18n.t('screens.meeting.catering.detail.alert.modifySuccess'),
          })
          setFormMode('DETAIL')
        })
        .catch((err) => {
          Logger.error(err)
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t('screens.meeting.catering.detail.alert.modifyFail'),
          })
        })
    }
  }

  const deleteInstant = () => {
    analytics.event({
      event_feature: values.eventName.catering,
      event_action: values.actions.deleteBooking,
      event_object_id: id,
    })

    Alert.close()
    api
      .deleteCateringInstant(id)
      .then((deletedInstant) => {
        setDetail(deletedInstant)
        Alert.open({
          title: i18n.t('common.confirmation'),
          description: i18n.t('screens.meeting.catering.detail.alert.deleteSuccess'),
        })
      })
      .catch((err) => {
        Logger.error(err)
        Alert.open({
          title: i18n.t('common.error'),
          description: i18n.t('screens.meeting.catering.detail.alert.deleteFail'),
        })
      })
  }

  const openConfirmation = (type: 'modify' | 'delete') =>
    Alert.open({
      title: type === 'modify' ? i18n.t('common.warning') : i18n.t('screens.meeting.catering.detail.alert.deleteTitle'),
      description: type === 'modify' ? i18n.t(`screens.meeting.catering.detail.alert.${type}Confirm`) : '',
      buttons: [
        { label: i18n.t('common.cancel'), onClick: Alert.close, style: 'secondary' },
        {
          label: i18n.t(`common.${type}`),
          onClick: type === 'modify' ? modifyInstant : deleteInstant,
        },
      ],
    })

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />

      <Main>
        <MainContainer>
          <Tree
            previousPages={[
              { url: 'meeting/catering', title: 'screens.meeting.title_breadcrumb' },
              ...(formMode === 'UPDATE'
                ? [{ url: `meeting/catering/${id}`, title: 'screens.meeting.catering.detail.title' }]
                : []),
            ]}
            currentPageTitle={i18n.t(formMode === 'UPDATE' ? 'common.modify' : 'screens.meeting.catering.detail.title')}
          />
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !detail || !instantStatus ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <CateringDetailContainer>
              <ContentContainer>
                <CateringForm
                  bookedInstant={detail}
                  mode={formMode}
                  onValuesChange={(modifiedFields) => setModifiedValues({ ...modifiedValues, ...modifiedFields })}
                  onErrorsChange={setFormErrors}
                  instantStatus={instantStatus}
                />
              </ContentContainer>
              <ButtonsContainer>
                <>
                  {formMode === 'UPDATE' ? (
                    <Button
                      label={i18n.t('common.validate')}
                      onClick={() => !isFormInvalid && openConfirmation('modify')}
                      disabled={instantStatus !== 'editable' || isFormInvalid}
                    />
                  ) : (
                    <Button
                      label={i18n.t('common.modify')}
                      onClick={() => setFormMode('UPDATE')}
                      disabled={instantStatus !== 'editable'}
                    />
                  )}
                </>
                <>
                  {formMode === 'UPDATE' ? (
                    <Button
                      label={i18n.t('common.cancel')}
                      onClick={() => {
                        setFormMode('DETAIL')
                        setModifiedValues(undefined)
                      }}
                      disabled={instantStatus !== 'editable'}
                      style="secondary"
                    />
                  ) : (
                    <Button
                      label={i18n.t('common.delete')}
                      onClick={() => openConfirmation('delete')}
                      disabled={instantStatus !== 'editable'}
                      style="danger"
                    />
                  )}
                </>
              </ButtonsContainer>
            </CateringDetailContainer>
          )}

          <Toast />
        </MainContainer>
      </Main>
    </ScreenContainer>
  )
}

export default CateringDetailScreen

const ScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: calc(100vh - ${HEADER_HEIGHT + 80}px);

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const MainContainer = styled.div`
  padding: 40px 76px;
  display: flex;
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 40px 35px;
  }
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const CateringDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 60%;
`

// BUTTONS

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  align-self: flex-start;
  top: 40px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: row;
    align-self: center;
  }
`

// ERROR

const ErrorContainer = styled('div')`
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-top: 50px;
  flex: 1;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
`
