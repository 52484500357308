import ws from 'utils/Webservice'

const api = {
  schedules: () => ws<Schedules>(`/schedules`, 'GET'),

  schedule: (id: string) => ws<ScheduleData>(`/schedules/${id}`, 'GET'),

  search: (siteId: number, body: RoomPlanningSearchRequest) =>
    ws<RoomPlanningSearchResponse>(`/search/room?siteId=${siteId}`, 'POST', body),

  referentiels: (siteId: number) => ws<ReferentielList>(`/contrib/referentiels?siteId=${siteId}`, 'GET'),

  getReservations: () => ws<Schedules>(`/schedules`, 'GET'),

  createReservation: (body: RoomReservationWithLabel) =>
    ws<RoomReservationResponseWithLabel>(`/reservation/room`, 'POST', body),

  deleteReservation: (id: string, email: string) => {
    const body: RoomEmail = { email }
    return ws(`/reservation/${id}`, 'DELETE', body)
  },
}

export default api
