import ws from 'utils/Webservice'

const api = {
  all: (siteId: number) => ws<RestaurantListV6>(`/restauration?siteId=${siteId}`, 'GET'),

  get: (siteId: number, restaurantId: string) =>
    ws<RestaurantV6>(`/restauration/${restaurantId}?siteId=${siteId}`, 'GET'),
}

export default api
